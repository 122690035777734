.orderEntryForm {
  width: min-content;
}

.addressItemCell {
  height: 50px;
}

.addressItemCellContent {
  width: 100%;
  text-align: center;
}

.readOnlyInput {
  color: unset !important;
}

.amountInput {
  text-align: right !important;
}

.customerInfoCard {
  gap: 0.5rem;
  padding: 0 0.5rem;

  & :global .ant-form-item {
    margin-bottom: 0.5rem;
  }

  & :global {
    .order-no {
      flex: 6;
    }
    .customer-no {
      flex: 7;
    }
    .date {
      flex: 6.5;
    }
    .phone {
      flex: 8;
    }
    .clerk {
      flex: 4;
    }
    .email {
      flex: 18.5;
    }
    .registry-no {
      flex: 8;
    }
  }
}
.customerInfoCardWrapper {
  margin: 0;
  width: 1072px;
}

.customerAddressCard {
  .titleCard {
    padding: 1rem;
    line-height: 24px;
    padding-left: 2.5rem;
    font-size: 16px;
  }
  .billingCard {
    gap: 1px;
    padding: 1rem;
    width: 297px;
    & :global .ant-form-item-control-input {
      min-height: 24px;
      height: 24px;
      input {
        height: 24px;
        padding: 0 0.5rem;
      }
    }
    .addressNameInput {
      padding-right: 4rem;
    }
    .cityStateZipWrapper {
      display: flex;
      gap: 0.5rem;
      .city {
        flex: 7.5;
      }
      .state {
        flex: 2.5;
      }
      .zipCode {
        flex: 5.5;
      }
    }
  }

  & > div {
    margin: 0;
  }
}
.specialInstructionsCard {
  margin: 0;
  flex: 1;
  position: relative;
  padding-top: 1rem;
}
.showInstructionsInput {
  position: absolute;
  right: 5rem;
  top: 0;
  background-color: white;
}
.brideGroomeInput {
  position: absolute;
  left: 38rem;
  top: 0;
  background-color: white;
}

.customerAddressWrapper {
  padding-top: 1rem;
  display: flex;
  width: 1072px;
  position: relative;
  & :global .ant-form-item {
    margin: 0;
  }
}
.shipSameInput {
  position: absolute;
  left: 15rem;
  top: 0;
  background-color: white;
}

.amountsCard {
  gap: 0.25rem;
}
.amountsWrapper {
  width: 1072px;
  margin: 0;
  margin-top: 0.5rem;
  gap: 0.25rem;
  justify-content: space-between;
  & :global .ant-form-item {
    margin-bottom: 0.5rem;
    .ant-form-item-row {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 0.25rem;
      .ant-form-item-label {
        overflow: unset;
      }
      .ant-col > label {
        white-space: nowrap;
        font-size: 16px;
      }
    }
  }
}

.amountPaymentButton {
  margin: auto;
}

.orderLines {
  & :global .ant-form-item {
    margin-bottom: 0.5rem;
  }
}

.orderLineItemNumber {
  & :global .ant-form-item-control-input {
    max-width: 13rem;
  }
}

.form {
  & :global .ant-form-item{
    margin-bottom: 0;
  .ant-form-item-label {
    padding-bottom: 0;
  }
}
}