.ant-menu-item {
  border-radius: 0 !important;
}

.ant-menu-item.ant-menu-item-selected {
  width: 100%;
  margin: 0 !important;
  border-right: white solid 6px;
  border-left: white solid 6px;
  background: transparent !important;
}

.slick-track {
  height: 100% !important;
}

.ant-divider:before {
  content: none !important;
}

.ant-divider-inner-text {
  padding: 0 0.5rem !important;
}

.ant-menu-sub {
  background: transparent !important;
}

.ant-form-item-label > label {
  font-size: 16px !important;
}

.ant-checkbox-wrapper {
  font-size: 16px !important;
}