@import "variables";


// Media Queries
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";

.width-15 {
  width: 15% !important;
}

.width-20 {
  width: 20% !important;
}

.width-40 {
  width: 40% !important;
}

@media #{$mq-large} {
  .width-50-lg {
    width: 50% !important;
  }
}

@media #{$mq-xlarge} {
  .width-50-lg {
    width: 60% !important;
  }
}

@media #{$mq-xxlarge} {
  .width-50-lg {
    width: 60% !important;
  }
}


