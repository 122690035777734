.form-section-label {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

.action-container {
  button {
    font-weight: bold;
  }
}


.content-size {
  margin: 0;
  width: 1072px;
}
