@import "../../../../styles/variables";

.authContainer {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.authLogoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(153.73deg, #A0B5EB 0%, #C9F0E4 100.6%);

  img {
    width: 50%;
  }
}

.headerContainer {
  box-shadow: 0 0 2rem 0 rgb(41 48 66 / 10%);
}

.authMain {
  width: 100%;
  padding: 7em 12em;
}

.protectedContainer {
  height: 100vh;
  background: #ffffff !important;
}

.protectedContent {
  max-height: 100vh;
  overflow-y: auto;
}

.siderContainer {
  background: $XBaseColor !important;
  display: flex;
  flex-direction: column;
}
