.report {
  table {
    border: none;
    [class~="ant-table-thead"]{
      th {
        background-color: #FFF;
        border: none;
        border-radius: 0 !important;
        &:before {
          display: none;
        }
      }
    }
    [class~="ant-table-tbody"] {
      td {
        border: none;
        position: relative;
      }
    }
    th[class~="ant-table-cell"]{
      padding:4px;
      font-size: 10px !important;
      font-weight: bold;
    }
    td[class~="ant-table-cell"]{
      padding:4px;
      font-size: 8px !important;
      font-weight: normal;
    }
  }
  header {
    table {
      tbody {
        display: none !important;
      }
    }
  }
  label {
    font-size: 10px !important;
  }
  [class~="itemTable"] {
    table {
      thead {
        visibility: hidden !important;
        th[class~="ant-table-cell"]{
          padding:0px;
          height: 0;
        }
      }

    }
  }

  .report-header {
    position: fixed;
    top: 0.3cm;
    left: 1cm;
    width: calc(var(--letter-width) - 2cm) ;

  }
}
