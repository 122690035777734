.order-report {
  padding: 0.3cm 1cm ;
  position: relative;
  * {
    font-family: 'TimeNewRomance', serif;
  }

  .order-main {
    position: relative;
  }

  .invoice-wrapper {
    width: 60%;
  }
  .address-wrapper {
    width: 77%;
    line-height: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    span {
      padding-left: 42px;
      display: flex;
    }
  }

  font-size: 10px !important;
  background-color: white;
  height: var(--a5-height);
  display: flex;
  flex-direction: column;

  overflow: hidden;
  label {
    font-size: 11px !important;
  }





  [class~="ant-table-wrapper"] {
    &[class~="invoice"]{
      *{ background-color: transparent}
      [class~="ant-table-title" ]{
        font-size: 15px !important;
        font-weight: 500;
        border: none
      }
      [class~="ant-table-thead"]{
          th {
            border-inline-end: none;
            border-top: none;
          }
        }
    }
    &[class~="itemTable"]{
      border-bottom: 1px solid #000 !important;

      th {
        text-align: center !important;
      }
table{
  height: 160px;
  tbody {
    vertical-align: top;
  }
  tr{

    background-color: #fff;
    td{
      height: 19px;
    }
    &:hover {
      background-color: #fff;
    }
  }
}
      td[class~="ant-table-cell"]{
       border-bottom: none !important;
        padding : 0 4px!important;
      }
    }
    table {
      border-color: #000;
      [class~="buy-row"] {
        min-height: 100px;
      }
      [class~="instruction-cell"] {
        display: flex;
        padding:12px;
        width: 100%;
        height: 100%;
        flex-direction: row;
        position: absolute;
        top:0;
        > div {
        width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding:0 2px;
      }
        div:first-child {
          border-right: 1px solid #000;
        }
      }
    }
    [class~="ant-table-title" ]{
      padding:0;
      border: none
    }
    [class~="ant-table-thead"]{
      tr th:last-child {
        border-right: none !important;
      }
      th {
        background-color: #FFF;
        border-bottom: 1px solid #000;
        border-top: 1px solid #000;
        border-radius: 0 !important;
        border-right: 1px solid #000;
        &:before {
          display: none;
        }
      }
    }

    [class~="ant-table-tbody"] {
      td {
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
        position: relative;
      }
      tr td:last-child  {
        border-right: none;

      }
    }

    th[class~="ant-table-cell"]{
      padding:0 2px;
      font-size: 10px !important;
      font-weight: normal;
    }
    td[class~="ant-table-cell"]{
      padding:0 2px;
      font-size: 11px !important;
      font-weight: normal;
    }
  }


  [class~="ant-table-footer"]{
    padding: 0 2px;
    font-size: 10px !important;
    font-weight: bold;
    text-align: center;
    background-color: #FFF;
  }

  [class~="ant-space"]{
    margin-top: 4px;
  }

  [class~="ant-descriptions"]{
    padding-top: 1px;

    [class~="ant-descriptions-item-content"] {
      text-align: right !important;
    }
    [class~="ant-descriptions-view"] {
      width: 201px;
      border-top: none;
      border-right: none;
      border-left: 1px solid #000;
      border-radius: 0;

      th, td {
        padding:2px !important;
        width: 100px;
        text-align: center;
        background-color: #fff !important;
        border-bottom: 1px solid #000;
        font-size: 11px;
      }

      th {
        border-right: 1px solid #000 !important;
      }
    }
  }


  [class~="privacy"] {
    width: 100%;
    text-align: justify;
    text-justify: inter-character;
  }

  .divider {
    height: 115px;
    margin:auto 4px;
    border-inline-start: 1px solid #000;
  }

  .divider-dashed {
    position: absolute;
    bottom: 0;
    border-block-start: 1px dashed #000;
    margin: 0 0 0 20px;
    right: 0 ;
    min-width: 100px;
    width: 80%;
  }
}
