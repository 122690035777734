.appraisal-report {
  padding: 0.3cm 1cm ;
  position: relative;
  * {
    font-family: 'TimeNewRomance', serif;
    font-size: 12px;
  }
  font-size: 12px !important;
  background-color: white;
  height: var(--letter-height);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  label {
    font-size: 12px !important;
  }
  b {
    font-size: 12px;
    font-weight: bold;
  }
  .address-wrapper {
    width: 100%;
    line-height: 15px;
    font-size: 12px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 100px;
  }

  [class~="ant-table-wrapper"] {
    table {
      height: 700px;
      border-color: #000;
      overflow: hidden;
    }

    [class~="image"] {
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }
    [class~="description-col"] {
      border-right: none !important;
    }


    [class~="ant-table-title" ]{
      padding:0;
      border: none
    }
    [class~="ant-table-thead"]{
      tr th:last-child {
        border-right: none !important;
      }
      th {
        background-color: #FFF;
        border-bottom: 1px solid #000;
        border-top: 1px solid #000;
        border-radius: 0 !important;
        border-right: 1px solid #000;
        &:before {
          display: none;
        }
      }
    }

    [class~="ant-table-tbody"] {

      td {
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
        position: relative;

      }
      tr td:last-child  {
        border-right: none;

      }


    }

    th[class~="ant-table-cell"]{
      padding:2px;
      font-weight: normal;
      font-size: 11px;

    }
    td[class~="ant-table-cell"]{
      padding:4px;
      font-weight: normal;
      border-bottom: none;
      vertical-align: top;
    }
  }
  [class~="privacy"] {
    width: 100%;
    text-align: justify;
    text-justify: inter-character;
  }


    [class~="header-image"] {
      h1 {
        font-size: 54px;
        font-style: italic;
        font-weight: normal !important;
      }
      margin-top: -61px;
      margin-right: auto;
      margin-left: auto;
    }

  .footer {
    p {
      margin: 0;
      padding: 2px 0;
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
      text-align: justify;
    }

  }
}
