.input-card {
  border: 1px solid lightgray;
  padding: 0.5rem;
  margin: 0.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 0.6rem;
  .input-title {
    position: absolute;
    top: 0;
    left: 0.5rem;
    transform: translateY(-50%);
    font-size: 16px;
    background-color: white;
    padding: 0.25rem;
  }
  &[data-noborder="true"] {
    border-color: transparent;
  }
}
