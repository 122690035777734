@import "../../../styles/variables";

.orderEntryForm {
  width: min-content;
}

.addressItemCell {
  height: 50px;
}

.addressItemCellContent {
  width: 100%;
  text-align: center;
}

.readOnlyInput {
  color: unset !important;
}

.amountInput {
  text-align: right !important;
}

.customerInfoCard {
  gap: 0.5rem;
  padding: 0 0.5rem;

  & :global .ant-form-item {
    margin-bottom: 0.25rem;
    .ant-form-item-label {
      padding-bottom: 0;
      // text-align: center;
    }
  }

  & :global {
    .order-no {
      flex: 6;
    }
    .customer-no {
      flex: 7;
    }
    .date {
      flex: 7.5;
    }
    .phone {
      flex: 8;
    }
    .email {
      flex: 10.5;
    }
    .clerk {
      flex: 7;
    }
    .registry-no {
      flex: 8;
    }
  }
}
.customerInfoCardWrapper {
  margin: 0;
  width: 1072px;
}

.customerAddressCard {
  .titleCard {
    padding: 1rem;
    line-height: 24px;
    // padding-left: 2.5rem;
    font-size: 16px;
  }
  .billingCard {
    gap: 1px;
    padding: 1rem;
    width: 21rem;
    & :global .ant-form-item-control-input {
      min-height: 24px;
      height: 24px;
      input {
        height: 24px;
        padding: 0 0.5rem;
      }
    }
    .addressNameInput {
      padding-right: 4rem;
    }
    .cityStateZipWrapper {
      display: flex;
      gap: 0.5rem;
      .city {
        flex: 7.5;
      }
      .state {
        flex: 2.5;
      }
      .zipCode {
        flex: 5.5;
      }
    }
  }

  & > div {
    margin: 0;
  }
}
.specialInstructionsCard {
  margin: 0;
  flex: 1;
  position: relative;
  padding-top: 1rem;
}
.showInstructionsInput {
  position: absolute;
  right: 1rem;
  top: 0;
  background-color: #efefef !important;
}
.brideGroomeInput {
  position: absolute;
  left: 42.5rem;
  top: 0;
  background-color: #efefef !important;
  label {
    font-size: 12px !important;
  }
}

.customerAddressWrapper {
  padding-top: 1rem;
  display: flex;
  width: 1072px;
  position: relative;
  & :global .ant-form-item {
    margin: 0;
  }
}
.shipSameInput {
  position: absolute;
  left: 36rem;
  top: 0;
  background-color: #efefef !important;
  label {
    font-size: 12px !important;
  }
}

.amountsCard {
  gap: 0.25rem;
}
.amountsWrapper {
  width: 1072px;
  margin: 0.5rem 0;
  gap: 0.25rem;
  justify-content: space-between;
  & :global .ant-form-item {
    margin-bottom: 0;
    .ant-form-item-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 0.25rem;
      .ant-form-item-label {
        overflow: unset;
        padding-bottom: 0;
      }
      .ant-col > label {
        white-space: nowrap;
        font-size: 16px;
      }
    }
  }
}

.amountPaymentButton {
  margin: auto;
}

.orderLines {
  td {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem ;
  }
  & :global .ant-form-item {
    margin-bottom: 0;
  }
}

.orderLineItemNumber {
  & :global .ant-form-item-control-input {
    max-width: 13rem;
  }
}

[class*="Order_orderEntryForm"] {
  input {
    font-weight: bold !important;

  }

  [class*="ant-select-selection-item"] {
    font-weight: bold !important;
  }
}

.orderRepairVersionButton {
  color: $Primary;
  margin-left: 10px;
  :hover{
    color: $Primary;
  }
}