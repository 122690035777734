@import "../../../styles/variables";

.ButtonStyle {
  color: $Gray;
}

.QuantityCaption {
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  text-align: right;
}

.QuantityTable {
  td {
    padding: 0.12rem;
  }
}
.SoldTable {
  td {
    padding: 0.25rem;
  }
}
.ComponentTable {
  td {
    padding: 0.12rem 0.25rem;
  }
}

.CertTable {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem 0.5rem;
}

.StoneNotes {
  margin-top: 0.25rem;
}

.stones-input {
  & :global {
    .ant-form-item-label {
      min-width: 4rem;
      text-align: start;
    }
  }
}
