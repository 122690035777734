.font-weight-600 {
  font-weight: 600;
}

@font-face {
  font-family: 'TimeNewRomance';
  src: url('./fonts/TimeNewRomance.woff2') format('woff2'),
  url('./fonts/TimeNewRomance.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
