.formRoot {
  max-width: 1440px;
  & :global .ant-form-item {
    margin-bottom: 0.5rem;
  }
  & :global {
    .flex {
      display: flex;
    }
    .flex-col {
      flex-direction: column;
    }
    .flex-1 {
      flex: 1;
    }
    .w-full {
      width: 100%;
    }
    .gap-1 {
      gap: 0.5rem;
    }
    .items-center {
      align-items: center;
    }
    .px-4 {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
.card {
  & :global .ant-form-item {
    margin-bottom: 0.3rem;
    .ant-form-item-row {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 0.25rem;
      .ant-form-item-label {
        overflow: unset;
      }
      .ant-col > label {
        white-space: nowrap;
        min-width: 7rem;
        font-size: 16px;
      }
    }
  }
}
.cardGroup {
  display: flex;
  gap: 1rem;
}

.radio {
  height: 32px;
}
