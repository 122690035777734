.wrapper {
  width: 100%;

  input {
    width: 10.3rem;
  }
}
.header {
  display: flex;
  justify-content: flex-end;
  input {
    width: 8rem;
  }
}
.dataPicker {
  width: 8rem;
}

.searchFormLabel {
  font-size: 12px;
  display: inline-block;
  width: 60px;
}

