@import "stimulsoft.designer.office2013.whiteblue";
@import "stimulsoft.viewer.office2013.whiteblue";
@import "bootstrap.overrde";
@import "ant.overrde";
@import "width";
@import "font";
@import "general";
@import "color";
@import "input";

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.pointer {
  cursor: pointer;
}


