@import 'bootstrap/dist/css/bootstrap.min.css';
@import "styles";



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  * {
    font-weight: bold !important;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Shell {
  padding: 20px;
}

.bg-gray {
  background: #efefef !important;
}

.border-gray {
  border-color: rgba(5, 5, 5, 0.06) !important;
}

/*** print css ***/
:root{
  --a5-width: 8.5in;
  --a5-height: 5.5in;
  --letter-width: 8.5in;
  --letter-height: 11in;
}

.print-show {
  display: none !important;
}

.loadFont{
  visibility: hidden;
  font-family: 'TimeNewRomance', serif;
}


@media print {
  /* Hide all elements inside #root */
  .rpv-print__body-printing #root {
    display: none;
  }

  /* Show the prepared pages */
  .rpv-print__zone {
    display: block;
  }

  * {
    -webkit-print-color-adjust: exact; /* Chrome/Safari/Edge/Opera */
    color-adjust: exact; /* Firefox */
  }
  @page {
    margin: 0;
  }
  .page-break {
    margin: 0.3cm 1cm;
    page-break-after: always;
  }
  .print-show {
    display: block !important;
  }
  .loadFont{
    display: none !important;
  }
  .print-hidden {
    display: none !important;
  }
  html,
  .bg-gray {
    background-color: #fff !important;
  }

  /*** hide leftbar & topbar & toolbar in layouts for print ***/
  /* leftbar */
  .ant-layout > aside.ant-layout-sider{
    display: none;
  }
  /* topbar */
  .ant-layout > aside + .ant-layout > div.bg-gray:nth-of-type(1){
    display: none !important;
  }
  /* toolbar */
  main.ant-layout-content .ant-spin-container > .d-flex{
    display: none !important;
  }
  /* extra page issue in print */
  html > *:not(body),
  body > *:not(#root),
  #root > *:not(.App) {
    display: none !important;
  }

  /* fixes other issues */
  main.ant-layout-content {
    padding: 0 !important;
    max-height: unset;
    overflow: inherit;
  }
  .App > .ant-layout{
    height: unset;
  }


/*** pages ***/
.print-page-order{
  page: order;
}
@page order{
  size: var(--a5-width) var(--a5-height);
  margin: 0;
}

.print-page-appraisal{
  page: appraisal;
}
@page appraisal{
  size: var(--letter-width) var(--letter-height);
  margin: 0;
}

.print-page-report{
  page: report;
}
@page report{
  size: var(--letter-width) var(--letter-height);
  margin: 0;
}

  .pdf-viewer-page {
    page: pdf-viewer
  }

}
