$XBaseColor: rgba(18, 37, 84, 1);
$LightColor: #EAEFFB;
$Primary: #1677ff;
$BlueColor1: #A0B5EB;
$BorderColor: #A0B5EB;
$BlueColor2: #ABBEED;
$MainGray: #a7adbb;
$Gray: #656f7d;
$Mute: #d9d9d9;
$BorderColor: #f0f0f0;

$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px



