.form-root {
  & :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-input {
      font-size: 12px;
    }
  }
}

.dollars-table {
  tr {
    display: flex;
    justify-content: stretch;
    td {
      flex: 1;
      padding: 0.12rem;
      display: flex;
      justify-content: center;
      font-size: 16px;
    }
  }
}

.types-first-input {
  & :global {
    .ant-form-item-label {
      min-width: 5rem;
      text-align: start;
    }
  }
}

.giftware-input {
  & :global {
    .ant-form-item-label {
      min-width: 5.5rem;
      text-align: start;
    }
  }
}