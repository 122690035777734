@import "../../../../styles/variables";

.menuLogo {
  background: $XBaseColor !important;
}

.menuSider {
  background: $XBaseColor !important;
  margin-top: 10%;
  height: calc(100vh - 175px);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.menuSiderFooter {
  background: $XBaseColor !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: $LightColor;
}

.userDisplayImage {
  border-radius: 50%;
  width: 50px;
  align-items: center;
}

.profileLink {
  color: $XBaseColor;
  text-decoration: none;
  font-weight: bold;
  font-size: 13px;
}
